import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Img1 from '../images/new/blog/post/jornada/img-1.jpg'
import Img2 from '../images/new/blog/post/jornada/img-2.jpg'
import Img3 from '../images/new/blog/post/jornada/img-3.jpg'
import Img4 from '../images/new/blog/post/jornada/img-4.jpg'

import { 
  section,
  container,
  content,
  column,
  columns
} from '../components/styles/Base.styles'


const BlogItem = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Jornada Multissensorial de 2023" />
    <NavBar />
    {/* <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div> */}
    <div css={[section, content]} style={{ paddingBottom: 0, marginTop: 112 }}>
      <div css={container}>
        <h1>Jornada Multissensorial de 2023</h1>
        <p>Aconteceu a primeira edição da Jornada Multissensorial na manhã de sábado, 20/05/2023, com o engajamento das famílias Kingdom. O evento juntou as unidades da Educação Infantil e do Fundamental I.</p>
        <p>O estímulo sensorial começou na entrada da festa, que ocorreu no jardim privilegiado da escola, na Unidade Kids, que conta com árvores diversas, arvorismo e um espaço vasto. Este foi praticamente coberto pelas toalhas e famílias que desfrutaram do piquenique.</p>
        <p>A Menu Infantil ofereceu um delicioso lanche com frutas, sucos, doces naturais e uma variedade de sanduíches deliciosos e de temperos, como hortelã, manjericão, cominho, entre outros, que exalavam o cheiro próximo às mesas. Tudo servido com muito sabor e beleza.</p>
        <p>As crianças, familiarizadas com o espaço, tomaram conta e puderam apresentar aos pais os detalhes da sua escola.</p>
        <p>A equipe da psicomotricidade, animada com a inauguração do “Studio K”, antigo espaço do circo que foi reformado, caprichou no circuito psicomotor.</p>
        <p>A parceira a Cetop, que faz um trabalho de estimução com crianças de zero a três anos no espaço escolar, trouxe diversas atividades, tais como bolinhas, líquido não newtoniano, grãos sensoriais, e muito filme plástico para os participantes deixarem a sua marca registrada.</p>
        <p>Houve também o espaço Criações Artísticas, que permitiu pais e filhos expressarem de forma livre a arte espontânea o que cada um traz em si.</p>
        <p>O Kihap demonstrou um pouco de sua arte marcial com uma linguagem própria às crianças que remetia aos super-heróis. Uma incrível abordagem para acessar a força indomável e direcionar para o melhor de cada um.</p>
        <p>Haviam tabuleiros de xadrez que a equipe Mearas organizou com atenção e foco, valorizando essa modalidade no evento. O xadrez proporciona inúmeros ganhos no desenvolvimento de habilidades cognitivas. Muitos participantes aderiram às peças com concentração.</p>
        <p>A equipe de dança trouxe fitas e usou o jazz para curtição e alegria das crianças. Com coreografias improvisadas e o toque da dança, a equipe, com leveza e harmonia, conduziu quem deu um sim para dança.</p>
        <p>O English Team desenvolveu  o Sensory Stations com recursos diversos proporcionou às famílias uma experiência significativa em Hearing, Touch, Smell and Taste.</p>
        <p>Houve, também, a presença da kalligra, que vem desenvolvendo um projeto preventivo no Ensino Fundamental, por meio de uma intervenção pedagógica assistida que busca uma estimulação para o aprendizado. O time de especialistas da Kalligra utilizou de jogos e recursos pedagógicos lúdicos para que crianças e familiares se divertissem e conhecessem um pouco do valioso trabalho e dessa parceria que traz inúmeros ganhos.</p>
        <p>Além disso, muitas outras atividades que trouxeram magia e envolvimento, como bolinha de sabão, cama de gato, espaguete e muito mais.</p>
        <p>Quer saber? Já deixou saudades.</p>
        <div css={columns}>
          <div css={column}>
            <img src={Img1} alt='' style={{ width: '100%' }} />
          </div>
          <div css={column}>
            <img src={Img2} alt='' style={{ width: '100%' }} />
          </div>
          <div css={column}>
            <img src={Img3} alt='' style={{ width: '100%' }} />
          </div>
          <div css={column}>
            <img src={Img4} alt='' style={{ width: '100%' }} />
          </div>
        </div>
				<div style={{ paddingBottom: 112 }} />
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default BlogItem
